// Properties that are shown/used in the components
const propertiesENG = {
  id: 'ID',
  name: 'Name',
  admin: 'Admin',
  reader: 'Reader',
  group: 'Group',
  companyID: 'Company ID',
  email: 'Email',
  email1: 'Company contact person',
  email2: 'Technical contact person',
  address: 'Address',
  users: 'Users',
  type: 'Type',
  model: 'Model',
  info: 'Info',
  state: 'State',
  phone: 'Phone number',
  active: 'Active',
  iccid: 'ICCID',
  lastUpdate: 'Last update',
  sentData: 'Sent data',
  temperature: 'Temperature',
  totalFlow: 'Total flow',
  consumption: 'Consumption',
  usage: 'Usage',
  charge: 'Charge',
  imei: 'IMEI',
  sourceIp: 'Source IP',
  msisdn: 'MSISDN',
  timestamp: 'Timestamp',
  ts: 'Last activity',
  comparisonType: 'Comparison type',
  voltage: 'Voltage',
  silence: 'Silence',
  GreaterThan: 'Greater than',
  LessThan: 'Less than',
  deviceAlert: 'Device alert',
  geofence: 'Geofence',
  description: 'Description',
  valueParameter: 'Limit value',
  resendInterval: 'Resend interval',
  resendIntervalMinutes: 'Resend interval',
  isDefaultGroup: 'Default group',
  threshold: 'Threshold',
  message: 'Message',
  accuracy: 'Accuracy',
  course: 'Course',
  acceleration: 'Acceleration',
  status: 'Status',
  totalDistance: 'Total distance',
  direction: 'Direction',
  speed: 'Speed',
  humidity: 'Humidity',
  installer: 'Installer',
  devicegroup: 'Device group',
  usergroup: 'User group',
  secret: 'Secret',
  key: 'Key',
  expiration: 'Expiration date',
  value: 'Value',
  internalId: 'Internal ID',
  json: 'JSON',
  deviceType: 'Device type',
  deviceModel: 'Device model',
  simStatus: 'SIM-Status',
  deviceModelId: 'Device model',
  internal_ID: 'Internal ID',
  numberOfSims: 'Number of SIM cards',
  formFactor: 'Card size',
  recipient: 'Recipient',
  shippingAddress: 'Shipping address',
  dataPlan: 'Data plan',
  postOffice: 'ZIP code, postal disctrict, country',
  iccid_info: 'ICCID (Unique serial number of SIM card)',
  notificationType: 'Notification type',
  sms: 'Text message',
  webhook: 'Webhook',
  notificationSchema: 'Notification schema',
  notificationTarget: 'Notification receiver',
  notificationGroup: 'Notification group',
  notificationGroupName: 'Notification group',
  notificationGroupMember: 'Notification item',
  notificationGroupMembers: 'Notification items',
  remove: 'Remove',
  co2: 'CO2',
  voc: 'VOC-Compounds',
  link: 'Link',
  payload: 'Payload',
  events: 'Events',
  dataEvent: 'Data event',
  altitude: 'Altitude',
  network: 'Network',
  signalStrength: 'Signal Strength',
  tilt: 'Tilt',
  latitude: 'Latitude',
  longitude: 'Longitude',
  satellite: 'Satellite',
  metertype: 'Meter type',
  liters: 'Liters',
  flow: 'Flow',
  errorcode: 'Error code',
  pressure: 'Pressure',
  signalstrength: 'Signal Strength',
  alert: 'Alert',
  parserTime: 'Parser time',
  not_found: 'ICCID not found',
  already_exists: 'ICCID already in use. By creating new device, data collection of old device will be disabled.',
  gps_unavailable: 'GPS Unavailable',
  gps_available: 'GPS Available',
  base_station_location: 'Base station location',
  updateStatus: 'Status',
  radius: 'Radius',
  authEnabled: 'Authorization',
  authHeader: 'Authorization Header',
  authKey: 'Authorization Key',
  file: 'File',
  totalvolume: 'Total volume',
  positivevolume: 'Positive volume',
  negativevolume: 'Negative volume',
  error: 'Error',
  locationsource: 'Location source',
  waterflow: 'Water flow',
  watertemperature: 'Water temperature',
  nameENG: 'Title (eng)',
  nameFI: 'Title (fi)',
  deviceData: 'Device data',
  dataType: 'Data type',
  fields: 'Fields',
  numerical: 'Numerical',
  distance: 'Location',
  string: 'String',
  boolean: 'Boolean',
  alertType: 'Alert type',
  device: 'Device',
  dataField: 'Datafield',
  inactivity: 'Inactivity',
  Equal: 'Equal',
  NotEqual: 'Not equal',
  ThresholdChanged: 'Threshold changed',
  propertyName: 'Property name',
  alertRuleType: 'Alert type',
  equalLimit: 'Threshold value',
  caseSensitive: 'Case sensitive',
  Numerical: 'Numerical',
  Distance: 'Location',
  String: 'String',
  Boolean: 'Boolean',
  Inactivity: 'Inactivity',
  inactivityLimitMinutes: 'Inactivity minutes',
  deviceName: 'Device name',
  alertRuleName: 'Name',
  runtime: 'Runtime',
  odometer: 'Odometer',
  created: 'Created',
  batterylevel: 'Battery level',
  dataSendCount: 'Data send count',
  companyAdmin: 'Administrator',
  deviceAdmin: 'Device manager',
  userAdmin: 'User manager',
  role: 'Role',
  visibleOnGui: 'Visible in user interface',
};

const propertiesFI = {
  id: 'ID',
  name: 'Nimi',
  admin: 'Ylläpito-oikeus',
  reader: 'Katseluoikeus',
  group: 'Ryhmä',
  companyID: 'Y-tunnus',
  email: 'Sähköposti',
  email1: 'Yrityksen yhteyshenkilö',
  email2: 'Tekninen yhteyshenkilö',
  address: 'Osoite',
  users: 'Käyttäjiä',
  type: 'Tyyppi',
  model: 'Malli',
  info: 'Info',
  state: 'Tila',
  phone: 'Puhelinnumero',
  active: 'Aktiivinen',
  iccid: 'ICCID',
  lastUpdate: 'Viimeisin päivitys',
  sentData: 'Lähetetty data',
  temperature: 'Lämpötila',
  totalFlow: 'Kokonaisvirtaus',
  consumption: 'Kulutus',
  usage: 'Käyttö',
  charge: 'Varaustila',
  imei: 'IMEI',
  sourceIp: 'Lähde IP',
  msisdn: 'MSISDN',
  timestamp: 'Päivämäärä',
  ts: 'Uusimmat tiedot',
  comparisonType: 'Vertailutyyppi',
  voltage: 'Jännite',
  silence: 'Hiljaisuuden valvonta',
  GreaterThan: 'Suurempi kuin',
  LessThan: 'Vähemmän kuin',
  deviceAlert: 'Laitteen hälytys',
  geofence: 'Geofence',
  description: 'Kuvaus',
  valueParameter: 'Raja-arvo',
  resendInterval: 'Uudelleenlähetys',
  resendIntervalMinutes: 'Uudelleenlähetys',
  isDefaultGroup: 'Oletusryhmä',
  threshold: 'Raja-arvo',
  message: 'Viesti',
  accuracy: 'Tarkkuus',
  course: 'Kurssi',
  acceleration: 'Kiihtyvyys',
  status: 'Tila',
  totalDistance: 'Matka',
  direction: 'Suunta',
  speed: 'Nopeus',
  humidity: 'Ilmankosteus',
  installer: 'Asentaja',
  devicegroup: 'Laiteryhmä',
  usergroup: 'Käyttäjäryhmä',
  secret: 'Secret',
  key: 'Avain',
  expiration: 'Vanhentumispäivämäärä',
  value: 'Arvo',
  internalId: 'Tunniste',
  json: 'JSON',
  deviceType: 'Laitetyyppi',
  deviceModel: 'Laitemalli',
  simStatus: 'SIM-Tila',
  deviceModelId: 'Laitemalli',
  internal_ID: 'Tunniste',
  numberOfSims: 'SIM korttien määrä',
  formFactor: 'Kortin koko',
  recipient: 'Vastaanottaja',
  shippingAddress: 'Postitusosoite',
  dataPlan: 'Liittymätyyppi',
  postOffice: 'Postinumero, postitoimipaikka, maa',
  iccid_info: 'ICCID (SIM-kortin yksilöivä sarjanumero)',
  notificationType: 'Ilmoitustyyppi',
  sms: 'Tekstiviesti',
  webhook: 'Webhook',
  notificationSchema: 'Ilmoitusmuoto',
  notificationTarget: 'Ilmoituksen vastaanottaja',
  notificationGroup: 'Ilmoitusryhmä',
  notificationGroupName: 'Ilmoitusryhmä',
  notificationGroupMember: 'Ilmoituskohde',
  notificationGroupMembers: 'Ilmoituskohteita',
  remove: 'Poista',
  co2: 'CO2',
  voc: 'VOC-Päästöt',
  link: 'Linkki',
  payload: 'Viesti',
  events: 'Tapahtumat',
  dataEvent: 'Datatapahtuma',
  altitude: 'Korkeus',
  network: 'Verkko',
  signalStrength: 'Signaalin vahvuus',
  tilt: 'Kallistuma',
  latitude: 'Leveysaste',
  longitude: 'Pituusaste',
  satellite: 'Satelliitti',
  metertype: 'Mittaustyyppi',
  liters: 'Litroja',
  flow: 'Virtaus',
  errorcode: 'Virhekoodi',
  pressure: 'Paine',
  signalstrength: 'Signaalin vahvuus',
  alert: 'Hälytys',
  parserTime: 'Käsittelyaika',
  not_found: 'ICCID ei löydy',
  already_exists: 'ICCID on jo käytössä. Uuden laitteen aktivointi lopettaa vanhan laitteen tiedonkeruun',
  gps_unavailable: 'GPS Ei saatavilla',
  gps_available: 'GPS saatavilla',
  base_station_location: 'Tukiasemasijainti',
  updateStatus: 'Tila',
  radius: 'Säde',
  authEnabled: 'Authorization',
  authHeader: 'Authorization Header',
  authKey: 'Authorization Key',
  file: 'Tiedosto',
  totalvolume: 'Kokonaiskulutus',
  positivevolume: 'Positiivinen kulutus',
  negativevolume: 'Negatiivinen kulutus',
  error: 'Virhe',
  locationsource: 'Sijaintilähde',
  waterflow: 'Virtaus',
  watertemperature: 'Veden lämpötila',
  nameENG: 'Otsake (eng)',
  nameFI: 'Otsake (fi)',
  deviceData: 'Laitedata',
  dataType: 'Datatyyppi',
  fields: 'Kentät',
  numerical: 'Numero',
  distance: 'Sijainti',
  string: 'Merkkijono',
  boolean: 'Boolean',
  alertType: 'Hälytystyyppi',
  device: 'Laite',
  dataField: 'Datakenttä',
  inactivity: 'Epäaktiivisuus',
  Equal: 'Yhtäsuuri',
  NotEqual: 'Erisuuri',
  ThresholdChanged: 'Raja-arvo muuttunut',
  propertyName: 'Kentän nimi',
  alertRuleType: 'Hälytystyyppi',
  equalLimit: 'Kynnysarvo',
  caseSensitive: 'Kirjainkoko merkityksellinen',
  Numerical: 'Numero',
  Distance: 'Sijainti',
  String: 'Merkkijono',
  Boolean: 'Tosi/epätosi',
  Inactivity: 'Passiivisuus',
  inactivityLimitMinutes: 'Passiivisuus minuutit',
  deviceName: 'Laitteen nimi',
  alertRuleName: 'Nimi',
  runtime: 'Käyttöaika',
  odometer: 'Odometer',
  created: 'Lisätty',
  batterylevel: 'Varaustaso',
  dataSendCount: 'Lähetyskerrat',
  companyAdmin: 'Pääkäyttäjä',
  deviceAdmin: 'Laitehallinta',
  userAdmin: 'Käyttäjähallinta',
  role: 'Rooli',
  visibleOnGui: 'Näytä käyttöliittymässä',
};

const eventsENG = {
  device_added: 'Device added',
  device_removed: 'Device removed',
  device_disabled: 'Device disabled',
  device_sim_status_change: 'Device SIM status changed',
  device_sim_changed: 'Device SIM changed',
  device_alerting: 'Device alerting',
  device_triggered_alert_on_ebiot: 'Device triggered alert on ebiot',
  device_data_missing: 'Device data missing',
  device_data_sent: 'Device data sent',
  group_added: 'Device group added',
  apikey_expiring: 'API Key expiring',
  device_verified: 'Device verified',
};

const eventsFI = {
  device_added: 'Laite lisätty',
  device_removed: 'Laite poistettu',
  device_disabled: 'Laite disabloitu',
  device_sim_status_change: 'Laitteen SIM tila muuttunut',
  device_sim_changed: 'Laitteen SIM vaihdettu',
  device_alerting: 'Laitteen hälytys',
  device_triggered_alert_on_ebiot: 'Laite hälyttää ebiotissa',
  device_data_missing: 'Laitteen data puuttuu',
  device_data_sent: 'Laite lähettänyt dataa',
  group_added: 'Laiteryhmä lisätty',
  apikey_expiring: 'API Avain vanhentumassa',
  device_verified: 'Laite verifioitu',
};

const pagesENG = {
  pages_login: 'Login',
  pages_frontpage: 'Frontpage',
  pages_devices: 'Device Data',
  pages_watermeters: 'Water Meters',
  pages_conditionmeters: 'Environmental Meters',
  pages_trackers: 'Trackers',
  pages_add_device: 'Add Device',
  pages_control: 'Management',
  pages_devicecontrol: 'Devices',
  pages_devicegroups: 'Device Groups',
  pages_usergroups: 'User Groups',
  pages_rights: 'Rights',
  pages_usercontrol: 'User Management',
  pages_profile: 'Profile',
  pages_company: 'Company Information',
  pages_alertrules: 'Alert Rules',
  pages_alerts: 'Alerts',
  pages_sim_orders: 'SIM-Orders',
  pages_notificationgroups: 'Notification Groups',
  pages_dataevents: 'Data events',
  pages_data_fields: 'Data fields',
  pages_smokedetectors: 'Smoke detectors',
};

const pagesFI = {
  pages_login: 'Kirjautuminen',
  pages_frontpage: 'Etusivu',
  pages_devices: 'Laitedata',
  pages_watermeters: 'Vesimittarit',
  pages_conditionmeters: 'Olosuhdemittarit',
  pages_trackers: 'Paikantimet',
  pages_add_device: 'Lisää Laite',
  pages_control: 'Hallinta',
  pages_devicecontrol: 'Laitteet',
  pages_devicegroups: 'Laiteryhmät',
  pages_usergroups: 'Käyttäjäryhmät',
  pages_rights: 'Oikeudet',
  pages_usercontrol: 'Käyttäjähallinta',
  pages_profile: 'Profiili',
  pages_company: 'Yrityksen tiedot',
  pages_alertrules: 'Hälytyssäännöt',
  pages_alerts: 'Hälytykset',
  pages_sim_orders: 'SIM-Tilaukset',
  pages_notificationgroups: 'Ilmoitusryhmät',
  pages_dataevents: 'Datatapahtumat',
  pages_data_fields: 'Datakentät',
  pages_smokedetectors: 'Palovaroittimet',
};

// Required for IoT components. These texts are visible in the ui.
const messagesENG = {
  button_login: 'Login',
  button_logout: 'Logout',
  button_save: 'Save',
  button_edit: 'Edit',
  button_cancel: 'Cancel',
  button_close_device: 'Close device',
  button_activate_device: 'Activate device',
  button_remove_device: 'Remove device',
  button_remove_group: 'Remove group',
  button_remove: 'Remove',
  button_confirm_remove: 'Confirm remove',
  button_create_new: 'Create new',
  button_add: 'Add',
  button_add_user: 'Add user',
  button_remove_user: 'Remove user',
  button_remove_rule: 'Remove rule',
  button_create_apikey: 'Create API Key',
  button_validate: 'Validate',
  button_send: 'Send',
  button_close_sim: 'Close SIM card',
  button_search: 'Search',
  button_reinvite: 'Reinvite',
  text_alerts: 'Alerts',
  text_active_alerts: 'Active alerts',
  text_add_devices: 'Add devices',
  text_device: 'Device',
  text_devices: 'Devices',
  text_devices1: 'Devices',
  text_connected: 'Connected',
  text_active: 'Active',
  text_inactive: 'Inactive',
  text_devicegroups: 'Device groups',
  text_usergroups: 'User groups',
  text_usergroups_rights: 'User group rights',
  text_devicegroup_name: 'Device group name',
  text_usergroup_name: 'User group name',
  text_create_new_devicegroup: 'Create new device group',
  text_create_new_usergroup: 'Create new user group',
  text_rights: 'Rights',
  text_all_devices: 'All devices',
  text_group_devices: 'Group devices',
  text_device_information: 'Device information',
  text_search_devices: 'Search devices',
  text_search_by: 'Search by',
  text_search_devices_by: 'Search devices by',
  text_geofence_on: 'Geofence ON',
  text_geofence_alert_in: 'Alert inside area',
  text_geofence_alert_out: 'Alert outside area',
  text_geofence_settings: 'Geofence settings',
  text_geofence_help: 'Set geofence by selecting point from map',
  text_or: 'or',
  text_settings: 'Settings',
  text_user: 'User',
  text_users: 'Users',
  text_all_users: 'All users',
  text_group_users: 'Group users',
  text_search_users: 'Search users',
  text_email_notifications: 'Email notifications',
  text_email_receivers: 'Email receivers',
  text_email_help: 'Seperate addresses with comma',
  text_update_interval: 'Update interval',
  text_edit_company: 'Edit company information',
  text_management: 'Management',
  text_devicemanagement: 'Device management',
  text_usermanagement: 'User management',
  text_confirm_remove: 'Are you sure you want remove this group?',
  text_data_between: 'Data between',
  text_watermeters: 'Water meters',
  text_environment: 'Environmental sensors',
  text_trackers: 'Trackers',
  text_links: 'Links',
  text_no_alerts: '- No Alerts - ',
  text_no_devices: '- No Device data -',
  text_no_users: '- No Users -',
  text_no_usergroups: '- No User Groups -',
  text_devicegroups_description: 'Manage device groups and API keys',
  text_usergroups_rights_description: 'Manage user group rights',
  text_usermanagement_description: 'Add or remove users and control their visibilities',
  text_company_information: 'Company information',
  text_company_description: 'Manage company information',
  text_devicecontrol_description: 'Manage devices and their properties',
  text_alert_rules: 'Alert rules',
  text_alert_rules_description: 'Create or remove alert rules',
  text_404: '404 There seems to be nothing here',
  text_return_to_frontpage: 'Return to frontpage',
  text_group_information: 'Group information',
  text_alerts_description: 'Active alerts and their information',
  text_edit_devicegroup: 'Edit device group',
  text_edit_usergroup: 'Edit user group',
  text_create_usergroup: 'Create user group',
  text_group_name: 'Group name',
  text_close: 'Close',
  text_create_new_alertrule: 'Create new alert rule',
  text_api_key_new: 'New API Key',
  text_no_apikeys: 'No API keys',
  text_api_key_new_info: 'Make sure to save generated information. This data is not saved to this service.',
  text_no_data: '- No data -',
  text_user_profile: 'Profile',
  text_change_password: 'Change password',
  text_control: 'Control',
  text_toggle_points: 'Show points',
  text_latest_properties: 'Latest properties',
  text_row_data: 'Row values',
  text_api_keys: 'API Keys',
  text_toggle_route: 'Show route',
  text_toggle_basestation_location: 'Locate using base station',
  text_add_devices_info: 'Add either single or multiple devices to the system',
  text_edit_alert_rule: 'Edit alert rule',
  text_disable_device: 'Disable device',
  text_sim_order: 'Order SIM cards',
  text_sim_order_info: 'Order new SIM cards',
  text_network_location_info: 'Network location is not available on NBIoT devices',
  text_to_beginning: 'first',
  text_to_end: 'last',
  text_next: 'next',
  text_previous: 'previous',
  text_rows: 'rows',
  text_profile_page_info: 'Edit your information',
  text_settings_page_info: 'Edit service related settings',
  text_profile_name_update: 'Updating your name shows only after next login',
  text_remove_selected: 'Remove selected',
  text_no_chart_data: 'Device has no table data',
  text_usage_rights: 'Usage Rights',
  text_selected: 'Selected',
  text_back: 'Back',
  text_remove_data: 'Delete data created by the device',
  text_sim_card: 'SIM card',
  text_sim_status: 'SIM card status',
  text_sim_remove_warning: 'Removing SIM card cannot be undone',
  text_sim_remove_info:
    'Closing the SIM card deactivates the card, so that its data transfer is terminated and the card no longer accumulates costs.',
  text_remove_device_warning: 'Removing the device cannot be undone',
  text_remove_device_info:
    'Removing the device removes the device from the system, so it no longer accumulates data. You can also decide whether you want to delete the data created by the device from the system.',
  text_remove_device: 'Removing device',
  text_close_sim: 'Close SIM card',
  text_remove_sim: 'Closing SIM card',
  text_date_prefix: 'date',
  text_edit_geofence: 'Edit geofence',
  text_notification_groups: 'Notification groups',
  text_notification_groups_info: 'Manage text message, email and webhook alerts',
  text_create_notificationgroup: 'Create notification group',
  text_edit_notificationgroup: 'Edit notification group',
  text_create_alertrule: 'Create alert rule',
  text_edit_alertrule: 'Edit alert rule',
  text_no_notifications: '- No notifications -',
  text_notification_members: 'Notification members',
  text_notification_group_for_device_alerts: 'Notification group for device generated alerts',
  text_alert_outside: 'Alert outside',
  text_alert_inside: 'Alert inside',
  text_alert_outside_inside: 'Alert outside and inside',
  text_alert_triggered: 'Alert triggered',
  text_dataevents_description: 'Get notifications from data events',
  text_edit_event_subscription: 'Edit event subscription',
  text_create_event_subscription: 'Create event subscription',
  text_order_created: 'Order confirmation sent to your email',
  text_device_added: 'Device added',
  text_geofence_updated: 'Geofence updated',
  text_profile_updated: 'Profile information updated',
  text_device_update_succesful: 'Device information updated',
  text_device_disabled: 'Device is now disabled',
  text_device_enabled: 'Device is now enabled',
  text_device_removed: 'Device removed',
  text_device_and_data_removed: 'Device and data removed',
  text_notificationgroup_saved: 'Notification Group saved',
  text_notificationgroup_removed: 'Notification Group removed',
  text_alertrule_added: 'Alertrule added to device',
  text_alertrule_removed_from_device: 'Alertrule removed from device',
  text_auto_refresh_description: 'Automatic data update ON',
  text_auto_refresh_off_description: 'Automatic data update OFF',
  text_green_description: 'Data updated within last 24 hours',
  text_yellow_description: 'Data updated within last 72 hours',
  text_red_description: 'Last update over 72 hours a ago',
  text_gps_icon_description: 'GPS Location',
  text_gps_off_icon_description: 'GPS Not available',
  text_antenna_icon_description: 'Access point location',
  text_antenna_icon_grey_description: 'GPS and access point location not available',
  text_eventorder_created: 'Data event subscription created',
  text_eventorder_edited: 'Data event subscription edited',
  text_eventorder_removed: 'Data event subscription removed',
  text_last_hour: 'Last hour',
  text_last_six_hour: 'Last 6 hours',
  text_last_twelve_hour: 'Last 12 hours',
  text_last_day: 'Last 24 hours',
  text_last_week: 'Last week',
  text_last_month: 'Last month',
  text_date_selection: 'Date range selection',
  text_documentation: 'Guide',
  text_active_company: 'Active company',
  text_single_device: 'Single device',
  text_mass_import: 'Multiple devices',
  text_error_headers: 'Verify that file contains same headers as document template',
  text_error_fields: 'Verify that required fields are not empty (ICCID, Name)',
  text_document_template: 'Document template',
  text_validation_result: 'Validation result',
  text_download: 'Download',
  text_common: 'Common',
  text_changelog: 'Changelog',
  text_import_step_1:
    'To add multiple devices you can download a customer-specific document template and fill it with information about devices you want to import.\n\nWhen you have filled the document: select it from you computer and send it to the system.\n\nAfter sending the file, you can see row-specific information about successful and incorrect rows.',
  text_import_step_2: 'Select the filled document',
  text_import_step_3: 'Upload the document',
  text_data_fields: 'Data fields',
  text_data_fields_info: 'Create custom field definitions for alerts',
  text_edit_data_field: 'Edit data field',
  text_invalid_json_format: 'Invalid JSON format',
  text_invalid_selected_fields: 'No field selected',
  text_invalid_selected_fields_distance:
    'Field selection is missing. Location data type requires 2 fields (1 = latitude, 2 = longitude).',
  text_datafield_created: 'Datafield created',
  text_datafield_updated: 'Datafield updated',
  text_datafield_removed: 'Datafield removed',
  text_alertrule_created: 'Alert rule created',
  text_alertrule_updated: 'Alert rule updated',
  text_alertrule_removed: 'Alert rule removed',
  text_remove_from_device: 'Remove from device',
  text_create_or_add: 'Create new or add existing',
  text_download_as_csv: 'Download as CSV',
  text_device_by_type: 'Devices by type',
  text_device_alertrules: 'Device alert rules',
  text_add_alertrule: 'Add alert rule to device',
  text_basic_information: 'Basic information',
  text_device_basic_information: 'Device basic information',
  text_device_state: 'Laitteen tila',
  text_sim_and_active_status: 'Device SIM-card and activity status',
  text_remove_device_or_sim: 'Remove device or close SIM-card',
  text_reinvite_sent: 'Reinvite sent',
  text_save_group_before_creating_apikeys: 'Save group before creating API keys',
  text_example: 'e.g:',
  text_fill_data_information: 'Select device or paste JSON data to "JSON" field. Select data type and target field',
  text_description_name: 'Name that is visible on the user interface.',
  text_description_notification_group: 'Notification group where generated alerts are sent.',
  text_description_resend_interval: 'Resend interval for generated alerts.',
  text_description_type: 'What field is used for the alert.',
  text_description_comparison_type: 'Comparison type.',
  text_description_radius: 'Geofencen radius in meters.',
  text_description_value: 'Value that triggers alert.',
  text_description_equal_limit:
    'Value range that triggers alert. E.q. when value is 10 and value range is 1: alert activates when target property is between 9 and 11.',
  text_description_value_parameter: 'Limit value',
  text_description_case_sensitive:
    'Whether upper and lower case letters are taken into account when activating the alarm condition.',
  text_description_boolean: 'True/untrue value',
  text_description_inactivity_limit_minutes: 'How many minutes the device is inactive before the alarm is activated.',
  text_show_datafields: 'Show datafields',
};

const messagesFI = {
  button_login: 'Kirjaudu sisään',
  button_logout: 'Kirjaudu ulos',
  button_save: 'Tallenna',
  button_edit: 'Muokkaa',
  button_cancel: 'Peruuta',
  button_close_device: 'Sulje laite',
  button_activate_device: 'Aktivoi laite',
  button_remove_device: 'Poista laite',
  button_remove_group: 'Poista ryhmä',
  button_remove: 'Poista',
  button_confirm_remove: 'Varmista poisto',
  button_create_new: 'Luo uusi',
  button_add: 'Lisää',
  button_add_user: 'Lisää käyttäjä',
  button_remove_user: 'Poista käyttäjä',
  button_remove_rule: 'Poista sääntö',
  button_create_apikey: 'Luo API avain',
  button_validate: 'Tarkista',
  button_send: 'Lähetä',
  button_close_sim: 'Sulje sim-kortti',
  button_search: 'Hae',
  button_reinvite: 'Kutsu uudelleen',
  text_alerts: 'Hälytykset',
  text_active_alerts: 'Aktiiviset hälytykset',
  text_add_devices: 'Lisää laitteita',
  text_device: 'Laite',
  text_devices: 'Laitteet',
  text_devices1: 'Laitteita',
  text_connected: 'Yhdistetty',
  text_active: 'Aktiivinen',
  text_inactive: 'Epäaktiivinen',
  text_devicegroups: 'Laiteryhmät',
  text_usergroups: 'Käyttäjäryhmät',
  text_usergroups_rights: 'Käyttäjäryhmän oikeudet',
  text_devicegroup_name: 'Laiteryhmän nimi',
  text_usergroup_name: 'Käyttäjäryhmän nimi',
  text_create_new_devicegroup: 'Luo uusi laiteryhmä',
  text_create_new_usergroup: 'Luo uusi käyttäjäryhmä',
  text_rights: 'Oikeudet',
  text_all_devices: 'Kaikki laitteet',
  text_group_devices: 'Ryhmän laitteet',
  text_device_information: 'Laitteen tiedot',
  text_search_devices: 'Hae laitteita',
  text_search_by: 'Hae kirjoittamalla',
  text_search_devices_by: 'Hae laitteita kirjoittamalla',
  text_geofence_on: 'Geofence päällä',
  text_geofence_alert_in: 'Hälytys alueella',
  text_geofence_alert_out: 'Hälytys ulkopuolella',
  text_geofence_settings: 'Geofence asetukset',
  text_geofence_help: 'Aseta geofence valitsemalla keskipiste kartalta',
  text_or: 'tai',
  text_settings: 'Asetukset',
  text_user: 'Käyttäjä',
  text_users: 'Käyttäjät',
  text_all_users: 'Kaikki käyttäjät',
  text_group_users: 'Ryhmän käyttäjät',
  text_search_users: 'Hae käyttäjiä',
  text_email_notifications: 'Sähköposti-ilmoitukset',
  text_email_receivers: 'Sähköpostin vastaanottajat',
  text_email_help: 'Erottele osoitteet pilkulla',
  text_update_interval: 'Päivitysintevalli (minuuttia)',
  text_edit_company: 'Muokkaa yrityksen tietoja',
  text_management: 'Hallinta',
  text_devicemanagement: 'Laitehallinta',
  text_usermanagement: 'Käyttäjähallinta',
  text_confirm_remove: 'Haluatko varmasti poistaa ryhmän?',
  text_data_between: 'Tiedot ajalta',
  text_watermeters: 'Vesimittarit',
  text_environment: 'Olosuhdemittarit',
  text_trackers: 'Paikantimet',
  text_links: 'Linkit',
  text_no_alerts: '- Ei Hälytyksiä - ',
  text_no_devices: '- Ei Laitedataa -',
  text_no_users: '- Ei Käyttäjiä -',
  text_no_usergroups: '- Ei käyttäjäryhmiä -',
  text_devicegroups_description: 'Hallitse laitteiden ryhmittelyä ja API avaimia',
  text_usergroups_rights_description: 'Hallitse käyttöoikeuksia',
  text_usermanagement_description: 'Lisää tai poista käyttäjiä sekä hallitse käyttäjien näkyvyyksiä',
  text_company_information: 'Yrityksen tiedot',
  text_company_description: 'Muokkaa yrityksen tietoja',
  text_devicecontrol_description: 'Hallitse laitteita ja niiden tietoja',
  text_alert_rules: 'Hälytyssäännöt',
  text_alert_rules_description: 'Luo tai poista hälytyssääntöjä',
  text_404: '404 Täältä ei löydy mitään',
  text_return_to_frontpage: 'Palaa etusivulle',
  text_group_information: 'Ryhmän tiedot',
  text_alerts_description: 'Aktiiviset hälytykset ja niiden tiedot',
  text_edit_devicegroup: 'Muokkaa laiteryhmää',
  text_edit_usergroup: 'Muokkaa käyttäjäryhmää',
  text_create_usergroup: 'Luo käyttäjäryhmä',
  text_group_name: 'Ryhmän nimi',
  text_close: 'Sulje',
  text_create_new_alertrule: 'Luo uusi hälytyssääntö',
  text_api_key_new: 'Uusi API avain',
  text_no_apikeys: 'Ei API avaimia',
  text_api_key_new_info: 'Kopioi luomasi kyselyrajapinnan tiedot talteen sillä niitä ei tallenneta palveluun',
  text_no_data: '- Ei dataa -',
  text_user_profile: 'Profiili',
  text_change_password: 'Vaihda salasana',
  text_control: 'Hallinta',
  text_toggle_points: 'Näytä pisteet',
  text_latest_properties: 'Viimeisimmät arvot',
  text_row_data: 'Rivikohtaiset arvot',
  text_api_keys: 'API Avaimet',
  text_toggle_route: 'Näytä reitti',
  text_toggle_basestation_location: 'Paikanna tukiaseman perusteella',
  text_add_devices_info: 'Lisää laitteita joko yksittäin tai massana järjestämään',
  text_edit_alert_rule: 'Muokkaa hälytyssääntöä',
  text_disable_device: 'Poista laite käytöstä',
  text_sim_order: 'SIM korttien tilaus',
  text_sim_order_info: 'Tilaa uusia SIM kortteja',
  text_network_location_info: 'Tukiasemasijainti ei ole saatavilla NBIoT laitteilla',
  text_to_beginning: 'alkuun',
  text_to_end: 'loppuun',
  text_next: 'seuraava',
  text_previous: 'edellinen',
  text_rows: 'rivejä',
  text_profile_page_info: 'Hallinnoi omia tietoja',
  text_settings_page_info: 'Muokkaa palvelun käyttöön liittyviä asetuksia',
  text_profile_name_update: 'Nimen päivittäminen näkyy vasta seuraavan kirjautumisen yhteydessä',
  text_remove_selected: 'Poista valitut',
  text_no_chart_data: 'Laitteella ei ole taulukko dataa',
  text_usage_rights: 'Käyttöoikeudet',
  text_selected: 'Valittu',
  text_back: 'Takaisin',
  text_remove_data: 'Poista myös laitteen luoma data',
  text_sim_card: 'SIM-kortti',
  text_sim_status: 'SIM-kortin tila',
  text_sim_remove_warning: 'SIM-kortin sulkemista ei voi peruuttaa',
  text_sim_remove_info:
    'SIM-kortin sulku poistaa kortin käytöstä, jolloin tämän tiedonsiirto lakkautetaan eikä kortti enää kerrytä kuluja.',
  text_remove_device_warning: 'Laitteen poistoa ei voi peruuttaa',
  text_remove_device_info:
    'Laitteen poisto poistaa laitteen järjestelmästä, jolloin sen datan keräys lakkautetaan ja laitteen tiedot poistetaan. Voit myös päättää haluatko poistaa laitteen luoman datan järjestelmästä.',
  text_remove_device: 'Laitteen poisto',
  text_close_sim: 'Sulje SIM-kortti',
  text_remove_sim: 'SIM-kortin sulku',
  text_date_prefix: 'pvm',
  text_edit_geofence: 'Aseta geofence',
  text_notification_groups: 'Ilmoitusryhmät',
  text_notification_groups_info: 'Hallitse tekstiviesti, sähköposti ja webhook hälytyksiä',
  text_create_notificationgroup: 'Luo ilmoitusryhmä',
  text_edit_notificationgroup: 'Muokkaa ilmoitusryhmää',
  text_create_alertrule: 'Luo hälytyssääntö',
  text_edit_alertrule: 'Muokkaa hälytyssääntöä',
  text_no_notifications: '- Ei ilmoituksia -',
  text_notification_members: 'Ilmoituskohteet',
  text_notification_group_for_device_alerts: 'Ilmoitusryhmä laitteen generoimille hälytyksille',
  text_alert_outside: 'Hälytys ulkopuolella',
  text_alert_inside: 'Hälytys sisäpuolella',
  text_alert_outside_inside: 'Hälytys ulkopuolella ja sisäpuolella',
  text_alert_triggered: 'Hälytys aktivoitunut',
  text_dataevents_description: 'Tilaa ilmoituksia datatapahtumista',
  text_edit_event_subscription: 'Muokkaa tapahtuman tilausta',
  text_create_event_subscription: 'Luo uusi tapahtuman tilaus',
  text_order_created: 'Tilausvahvistus lähetetty sähköpostiisi',
  text_device_added: 'Laite lisätty',
  text_geofence_updated: 'Geofence päivitetty',
  text_profile_updated: 'Profiilin tiedot päivitetty',
  text_device_update_succesful: 'Laitteen tiedot päivitetty',
  text_device_disabled: 'Laitteen tila muutettu epäaktiiviseksi',
  text_device_enabled: 'Laite on nyt aktiivinen',
  text_device_removed: 'Laite poistettu',
  text_device_and_data_removed: 'Laite ja sen datat poistettu',
  text_notificationgroup_saved: 'Ilmoitusryhmä tallennettu',
  text_notificationgroup_removed: 'Ilmoitusryhmä poistettu',
  text_alertrule_added: 'Hälytysääntö lisätty laitteelle',
  text_alertrule_removed_from_device: 'Hälytyssääntö poistettu laitteelta',
  text_auto_refresh_description: 'Datan automaattinen päivitys päällä',
  text_auto_refresh_off_description: 'Datan automaattinen päivitys pois päältä',
  text_green_description: 'Data päivittynyt viimeisen 24 tunnin sisällä',
  text_yellow_description: 'Data päivittynyt viimeisen 72 tunnin sisällä',
  text_red_description: 'Datan päivityksestä yli 72 tuntia',
  text_gps_icon_description: 'GPS Paikannus',
  text_gps_off_icon_description: 'GPS Ei saatavilla',
  text_antenna_icon_description: 'Tukiasemapaikannus',
  text_antenna_icon_grey_description: 'GPS ja tukiasemasijainti ei saatavilla',
  text_eventorder_created: 'Datatapahtuman tilaus luotu',
  text_eventorder_edited: 'Datatapahtuman tilausta muokattu',
  text_eventorder_removed: 'Datatapahtuman tilaus poistettu',
  text_last_hour: 'Viimeinen tunti',
  text_last_six_hour: 'Viimeiset 6 tuntia',
  text_last_twelve_hour: 'Viimeiset 12 tuntia',
  text_last_day: 'Viimeiset 24 tuntia',
  text_last_week: 'Viimeinen viikko',
  text_last_month: 'Viimeinen kuukausi',
  text_date_selection: 'Ajanjakson valinta',
  text_documentation: 'Ohje',
  text_active_company: 'Aktiivinen yritys',
  text_single_device: 'Yksittäinen laite',
  text_mass_import: 'Useita laitteita',
  text_error_headers: 'Varmista että tiedostossa on samat kentät kuin dokumenttipohjassa',
  text_error_fields: 'Varmista että pakolliset kentät eivät ole tyhjiä (ICCID, Name)',
  text_document_template: 'Dokumenttipohja',
  text_validation_result: 'Validoinnin tulos',
  text_download: 'Lataa',
  text_common: 'Yleistä',
  text_changelog: 'Muutosloki',
  text_import_step_1:
    'Laitteiden lisäystä varten voit ladata asiakaskohtaisen dokumenttipohjan, johon voit täyttää lisättävien laitteiden tiedot.\n\nTäydennettyäsi lisättävien laitteiden tiedot dokumenttipohjaan: valitse tiedosto koneeltasi ja lähetä se järjestelmään. \n\nLähetettyäsi tiedoston näet rivikohtaisen tiedon onnistuneista sekä virheellisistä riveistä.',
  text_import_step_2: 'Valitse täytetty dokumenttipohja',
  text_import_step_3: 'Lataa dokumenttipohja järjestelmään',
  text_data_fields: 'Datakentät',
  text_data_fields_info: 'Luo mukautettuja kenttiä hälytyksiä varten',
  text_edit_data_field: 'Muokkaa datakenttiä',
  text_invalid_json_format: 'Virheellinen JSON-muoto',
  text_invalid_selected_fields: 'Ei valittua kenttää',
  text_invalid_selected_fields_distance:
    'Kenttävalinta puuttuu. SijaintiSi datatyyppi vaatii 2 kenttää (1 = latitude, 2 = longitude).',
  text_datafield_created: 'Datakenttä luotu',
  text_datafield_updated: 'Datakenttä päivitetty',
  text_datafield_removed: 'Datakenttä poistettu',
  text_alertrule_created: 'Hälytyssääntö luotu',
  text_alertrule_updated: 'Hälytyssääntö päivitetty',
  text_alertrule_removed: 'Hälytyssääntö poistettu',
  text_remove_from_device: 'Poista laitteelta',
  text_create_or_add: 'Luo uusi tai lisää olemassa oleva',
  text_download_as_csv: 'Lataa CSV-muodossa',
  text_device_by_type: 'Laitteet tyypeittäin',
  text_device_alertrules: 'Laitteen hälytyssäännöt',
  text_add_alertrule: 'Lisää hälytyssääntö laitteelle',
  text_basic_information: 'Perustiedot',
  text_device_basic_information: 'Laitteen perustiedot',
  text_device_state: 'Laitteen tila',
  text_sim_and_active_status: 'Laitteen SIM-kortin ja aktiivisuuden tila',
  text_remove_device_or_sim: 'Poista laite tai sulje SIM-kortti',
  text_reinvite_sent: 'Uudelleenkutsu lähetetty',
  text_save_group_before_creating_apikeys: 'Tallenna ryhmä ennen API avaimien luomista',
  text_example: 'esim:',
  text_fill_data_information:
    'Valitse laite tai liitä JSON muotoinen data "JSON" kenttään. Valitse datatyyppi sekä kenttä.',
  text_description_name: 'Käyttöliittymässä näkyvä nimi.',
  text_description_notification_group: 'Ilmoitusryhmä, johon syntyvät hälytykset lähetetään.',
  text_description_resend_interval: 'Kuinka monen minuutin välein hälytysehdon täyttyminen aktivoi hälytyksen.',
  text_description_type: 'Mihin kenttään hälytysehto kohdistetaan',
  text_description_comparison_type: 'Vertailytyyppi',
  text_description_radius: 'Geofencen säde metreissä',
  text_description_value: 'Arvo, jolla hälytys aktivoidaan.',
  text_description_equal_limit:
    'Vaihteluväli jolla hälytys voi aktivoitua. Esim. ehto Arvo = 10 kynnysarvolla 1 sallii vaihteluvälin 9 - 11.',
  text_description_value_parameter: 'Raja-arvo',
  text_description_case_sensitive: 'Otetaanko isot- ja pienet kirjaimet huomioon hälytysehdon aktvoinnissa.',
  text_description_boolean: 'Tosi/epätosi arvo',
  text_description_inactivity_limit_minutes:
    'Kuinka monta minuuttia laite on epäaktiivisena ennen kuin hälytys aktivoidaan.',
  text_show_datafields: 'Näytä datakentät',
};

interface Messages {
  [key: string]: string;
}

export const finnish: Messages = {
  ...pagesFI,
  ...propertiesFI,
  ...eventsFI,
  ...messagesFI,
};

export const english: Messages = {
  ...pagesENG,
  ...propertiesENG,
  ...eventsENG,
  ...messagesENG,
};
